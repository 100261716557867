import { lazy } from 'react';

import { useRoutes } from 'react-router-dom';

import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';

const UserManagement = Loadable(lazy(() => import('views/User-management')));
const UserAddV2 = Loadable(lazy(() => import('views/User-management/User-add-v2')));
const UserEdit = Loadable(lazy(() => import('views/User-management/User-edit')));
const UserProfile = Loadable(lazy(() => import('views/User-management/User-profile')));
const TemperatureSettings = Loadable(lazy(() => import('views/temp-settings')));
const ResourceAgg = Loadable(lazy(() => import('views/resource-agg')));
const ContentManagement = Loadable(lazy(() => import('views/content-management')));
const ResourceEdit = Loadable(lazy(() => import('views/resource-edit')));
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const ArticePage = Loadable(lazy(() => import('views/single-article')));
const ChromeExtension = Loadable(lazy(() => import('views/chrome-extension')));

const AdminRoutesChildren = [
    {
        path: '/',
        element: <MainLayout/>,
        children: [
            {
                path: '/article/:id',
                element: <ArticePage />
            },
            {
                path: '/dashboard',
                element: <DashboardDefault />
            },
            {
                path: 'user-management',
                element: <UserManagement />,
                children: [
                    {
                        path: 'listing',
                        element: <UserManagement />
                    }
                ]
            },
            {
                path: 'user-add-v2',
                element: <UserAddV2 />,
            },

            {
                path: 'user-edit/:id',
                element: <UserEdit />,
            },

            {
                path: 'user-profile/:id',
                element: <UserProfile />,
            },
            {
                path: '/',
                element: <ResourceAgg />,
            },
            {
                path: 'temp-settings',
                element: <TemperatureSettings />,
            },
            {
                path: 'content-management',
                element: <ContentManagement />,
            },
            {
                path: 'resource-aggregation',
                element: <ResourceAgg />,
            },
            {
                path: 'resource-edit/:id',
                element: <ResourceEdit />,
            },
            {
                path: 'chrome-extension',
                element: <ChromeExtension />,
            }
        ]
    }
]

const ThemeRoutes = () => {

    const mainRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: AdminRoutesChildren
    };
  
    return useRoutes([mainRoutes])
};
  
export default ThemeRoutes;
