const DashboardIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-dashboard"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M13.45 11.55l2.05 -2.05"></path>
            <path d="M6.4 20a9 9 0 1 1 11.2 0z"></path>
        </svg>
    );
};

const UserCog = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-user-cog"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
            <path d="M6 21v-2a4 4 0 0 1 4 -4h2.5"></path>
            <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
            <path d="M19.001 15.5v1.5"></path>
            <path d="M19.001 21v1.5"></path>
            <path d="M22.032 17.25l-1.299 .75"></path>
            <path d="M17.27 20l-1.3 .75"></path>
            <path d="M15.97 17.25l1.3 .75"></path>
            <path d="M20.733 20l1.3 .75"></path>
        </svg>
    );
};

const TemperatureIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-thermometer" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M19 5a2.828 2.828 0 0 1 0 4l-8 8h-4v-4l8 -8a2.828 2.828 0 0 1 4 0z"></path>
            <path d="M16 7l-1.5 -1.5"></path>
            <path d="M13 10l-1.5 -1.5"></path>
            <path d="M10 13l-1.5 -1.5"></path>
            <path d="M7 17l-3 3"></path>
        </svg>
    )
}

const icons = { DashboardIcon, UserCog };


const dashboard = {
    id: 'Admin',
    title: 'Admin',
    caption: 'Administrative tools',
    type: 'group',
    children: [
        // {
        //     id: 'dashboard',
        //     title: 'Dashboard',
        //     type: 'item',
        //     url: '/dashboard',
        //     icon: icons.DashboardIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'user',
        //     title: 'User Management',
        //     type: 'item',
        //     url: '/user-management',
        //     icon: icons.UserCog,
        //     breadcrumbs: false
        // },
                {
            id: 'temperature',
            title: 'Category Temperature List',
            type: 'item',
            url: '/temp-settings',
            icon: TemperatureIcon,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
