import { Avatar, ListItem, ListItemText, Typography, ListItemAvatar, ListItemButton, ListItemIcon } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconLogout } from '@tabler/icons';
import { excecuteRequestv2 } from 'api/requestv2';

const MenuList = () => {
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="body2" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (<>
        <User/>
        {navItems}
        <Logout/>
        <br/>
    </>);
};

export default MenuList;

const User = () => {

    const [userProfile, setUser] = useState(false)
    const navigate = useNavigate();

    useEffect(()=> {
        fetchUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [])

    const { user } = useAuth0();
    
    async function fetchUser(){
        const response = await excecuteRequestv2(`/users/${user.sub}`)
        setUser(response)
    }

    const handleListItemClick = (route = '') => {
        if (route && route !== '') {
            navigate(route);
        }
    };

    return (
        <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}} onClick={(event) => handleListItemClick('/user-profile/' + userProfile.id)}>
            <ListItemAvatar>
                <Avatar alt={userProfile.name} src={userProfile.profile_picture} />
            </ListItemAvatar>
            <ListItemText  
            primary={
                <Typography variant='body'>
                {userProfile.name}
                </Typography>
            }
            secondary={
                <React.Fragment>
                <Typography variant='caption'>
                    {userProfile.job_title}
                </Typography>
                </React.Fragment>
            }
            />
        </ListItem>
    )
}

const Logout = () => {

    const { logout } = useAuth0();

    return (
        <>
         <ListItemButton
                sx={{ borderRadius: '12px'}}
                onClick={() => {
                    localStorage.removeItem('loggedin')
                    logout({ returnTo: window.location.origin 
                })}}
            >
                <ListItemIcon>
                    <IconLogout stroke={1.5} size="1.3rem" />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
            </ListItemButton>
        </>
    )
} 
