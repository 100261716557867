const ArticleIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-article"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
            <path d="M7 8h10"></path>
            <path d="M7 12h10"></path>
            <path d="M7 16h10"></path>
        </svg>
    );
};

const ChatIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-message-circle-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1"></path>
        </svg>
    );
};

const ContentIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-book-upload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M14 20h-8a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12v5"></path>
        <path d="M11 16h-5a2 2 0 0 0 -2 2"></path>
        <path d="M15 16l3 -3l3 3"></path>
        <path d="M18 13v9"></path>
        </svg>
    );
};




const HomeIcon = ()=> {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
            <path d="M10 12h4v4h-4z"></path>
        </svg>
    )
}

const icons = { ArticleIcon, ChatIcon, HomeIcon, ContentIcon };

const resources = {
    id: 'Reources',
    title: 'Resources',
    caption: 'Research tools',
    type: 'group',
    children: [
        // {
        //     id: 'homepage',
        //     title: 'Contents',
        //     type: 'item',
        //     url: '', //add the new cms link
        //     icon: icons.HomeIcon,
        //     breadcrumbs: false
        // },
        {
            id: 'resource',
            title: 'Links',
            type: 'item',
            url: '/resource-aggregation',
            icon: icons.ArticleIcon,
            breadcrumbs: false
        },
        {
            id: 'chrome-extension',
            title: 'Chrome Extension',
            type: 'item',
            url: '/chrome-extension',
            icon: icons.ContentIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'chat',
        //     title: 'Conversations',
        //     type: 'item',
        //     url: 'https://planetary-blue.vercel.app/',
        //     icon: icons.ChatIcon,
        //     breadcrumbs: false
        // }
    ]
};

export default resources;
